////// --------------------------------------------------
////// FONTS
////// --------------------------------------------------

h1,h2,h3,h4,h5,h6 {
  font-family: @font-exo;
}

nav {
  font-family: @font-exo;
}

////// --------------------------------------------------
////// NAVBAR
////// --------------------------------------------------

.navbar .nav > li > a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.navbar1 {
  margin-top: 30px;
}

.navbar1a {
  margin-top: 5px;
  font-family: @font-exo;
}

.navbar1b {
  position:relative;
  width:272px;
  height:51px;
  margin:0 auto;
  @media (max-width: 310px) {
    .responsive-invisibility();
  }
}

.navbar1b1 {
  z-index: 200;
  position:absolute;
  left:0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.navbar1b1:hover {
  opacity:0;
}

.navbar1b2 {
  z-index: 100;
  position:absolute;
  left:0;
}

.navbar1c {
  position:relative;
  width:191px;
  height:35px;
  margin:0 auto;
  @media (min-width: 311px) {
    .responsive-invisibility();
  }
}

.navbar2 {
  max-width: 542px
}

@media (min-width: @screen-lg-min) {
.navbar2 {
  margin-top: 40px;
}
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
.navbar2 {
  margin-top: 5px;
}
}

@media (max-width: @screen-md-min) {
.navbar { min-height:38px; border-radius: 6px; }
.navbar .navbar-brand{ padding: 0 12px;font-size: 16px;line-height: 38px;height: 38px; }
.navbar .navbar-nav > li > a { padding-top: 0px; padding-bottom: 0px; line-height: 38px; }
.navbar .navbar-toggle { margin-top: 2px; margin-bottom: 2px; margin-right: 5px; padding: 8px 9px; }
.navbar .navbar-form { margin-top: 2px; margin-bottom: 0px }
}

////// --------------------------------------------------
////// WELL
////// --------------------------------------------------

.well {
  a {
    color: darken(@azul3, 10%);
  &:hover,
  &:focus {
    color: lighten(@azul3, 5%);
  }
  }
}

////// --------------------------------------------------
////// SECTIONS
////// --------------------------------------------------

a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

////// --------------------------------------------------

.relativediv-section {
  position: relative;
}

////// --------------------------------------------------

.normallistgroup {
  padding: 0 0 0 15px;
}

////// --------------------------------------------------

@media (max-width: 365px) {
.g-recaptcha {
  transform:scale(0.77);
  transform-origin:0;
  -webkit-transform:scale(0.77);
  transform:scale(0.77);
  -webkit-transform-origin:0 0;
  transform-origin:0 0 0;
}
}

@media (max-width: 296px) {
.g-recaptcha {
  transform:scale(0.65);
  transform-origin:0;
  -webkit-transform:scale(0.65);
  transform:scale(0.65);
  -webkit-transform-origin:0 0;
  transform-origin:0 0 0;
}
}

////// --------------------------------------------------

.icon1 {
  position:relative;
  width:33px;
  height:32px;
  margin:0 auto;
}

.icon1a {
  z-index: 200;
  position:absolute;
  left:0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.icon1a:hover {
  opacity:0;
}

.icon1b {
  z-index: 100;
  position:absolute;
  left:0;
}

////// --------------------------------------------------

.welcome-section {
  height:100%;
}

.welcome1-section {
  min-height: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; 
    -webkit-box-align : center;
  -webkit-align-items : center;
       -moz-box-align : center;
       -ms-flex-align : center;
          align-items : center;
  width: 100%;
         -webkit-box-pack : center;
            -moz-box-pack : center;
            -ms-flex-pack : center;
  -webkit-justify-content : center;
          justify-content : center;
}

.welcome2-section {
  padding: 10px 0 10px 0;
}

.welcome2-section {
  @media (max-width: 485px) {
    .responsive-invisibility();
  }
}

.welcome2a-section {
  position:relative;
  width:472px;
  height:222px;
  margin:0 auto;
}

.welcome2a1-section {
  z-index: 200;
  position:absolute;
  left:0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.welcome2a1-section:hover {
  opacity:0;
}

.welcome2a2-section {
  z-index: 100;
  position:absolute;
  left:0;
}

.welcome3-section {
  padding: 20px 0 10px 0;
}

.welcome3-section {
  @media (min-width: 486px) {
    .responsive-invisibility();
  }
}

.welcome3a-section {
  position:relative;
  width:191px;
  height:90px;
  margin:0 auto;
}

.welcomewell {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid @fondoh;
  border-radius: @border-radius-base;
  .box-shadow(inset 0 1px 1px rgba(0,0,0,.05));
  blockquote {
    border-color: #ddd;
    border-color: rgba(0,0,0,.15);
  }
}

////// --------------------------------------------------

@media (min-width: @screen-lg-min) {
.cont1-section {
  margin-top: 50px;
}
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
.cont1-section {
  margin-top: 40px;
}
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
.cont1-section {
  margin-top: 30px;
}
}

@media (max-width: @screen-xs-max) {
.cont1-section {
  margin-top: 20px;
}
}

////// --------------------------------------------------

.planes-section {
  margin: 10px 0 0 0;
}

.planes1-section {
  margin-top: 10px;
  margin-bottom: 20px;
}

.planesi1-section {
  position: absolute;
  top: 25px;
  left: 400px;
}

.planesi1-section {
  @media (max-width: 525px) {
    .responsive-invisibility();
  }
}

.planesi2-section {
  position: absolute;
  top: 25px;
  left: 535px;
}

.planesi2-section {
  @media (max-width: 660px) {
    .responsive-invisibility();
  }
}

.planesi3-section {
  position: absolute;
  top: 25px;
  left: 475px;
}

.planesi3-section {
  @media (max-width: 600px) {
    .responsive-invisibility();
  }
}

////// --------------------------------------------------

.features1-section img {
  @media (max-width: 495px) {
    .responsive-invisibility();
  }
}

.features1-section ul {
  list-style-type: none;
  padding-left: 0;
}

.features1-section li {
  float: left !important;
  margin-bottom: 10px;
}

.features1-section a {
  margin-right: 10px;
}

@media (min-width: 495px) {
.features1-section a {
  margin-top: 30px;
}
}

@media (max-width: 495px) {
.features1-section a {
  margin-top: 0px;
}
}

.features2-section {
  margin-bottom: 20px;
}

////// --------------------------------------------------

.comprar-section {
  margin: 10px 0 50px 0;
}

.comprari1-section {
  position: absolute;
  top: 25px;
  left: 260px;
}

.comprari1-section {
  @media (max-width: 380px) {
    .responsive-invisibility();
  }
}

.comprar1-section {
  padding-top: 25px;
}

.comprar2-section {
  padding-top: 25px;
}

////// --------------------------------------------------

.pagar-section {
  margin: 10px 0 50px 0;
}

.pagari1-section {
  position: absolute;
  top: 25px;
  left: 260px;
}

.pagari1-section {
  @media (max-width: 380px) {
    .responsive-invisibility();
  }
}

.pagari2-section {
  position: absolute;
  top: 25px;
  left: 235px;
}

.pagari2-section {
  @media (max-width: 350px) {
    .responsive-invisibility();
  }
}

.pagar1-section {
  padding-top: 25px;
}

.pagar1a-section {
  padding-top: 45px;
}

.pagar2-section {
  padding-top: 25px;
}

.pagar3-section {
  padding-bottom: 20px;
}

.pagarwell {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: darken(@panel-bg, 6%);
  border: transparent;
  border-radius: @border-radius-base;
  .box-shadow(inset 0 1px 1px rgba(0,0,0,.05));
  blockquote {
    border-color: #ddd;
    border-color: rgba(0,0,0,.15);
  }
}

////// --------------------------------------------------

.buscardominios-section {
  margin: 10px 0 50px 0;
}

.buscardominiosi1-section {
  position: absolute;
  top: 28px;
  left: 320px;
}

.buscardominiosi1-section {
  @media (max-width: 445px) {
    .responsive-invisibility();
  }
}

.buscardominiosi2-section {
  position: absolute;
  top: 28px;
  left: 220px;
}

.buscardominiosi2-section {
  @media (max-width: 345px) {
    .responsive-invisibility();
  }
}

.buscardominios1-section {
  padding-top: 25px;
}

.buscardominios3-section {
  padding-top: 45px;
}

////// --------------------------------------------------

.reportedepago-section {
  margin: 10px 0 50px 0;
}

.reportedepagoi1-section {
  position: absolute;
  top: 25px;
  left: 300px;
}

.reportedepagoi1-section {
  @media (max-width: 417px) {
    .responsive-invisibility();
  }
}

.reportedepago1-section {
  padding-top: 25px;
}

.reportedepago1a-section {
  padding-top: 45px;
}

////// --------------------------------------------------

.quienessomos-section {
  margin: 10px 0 50px 0;
}

.quienessomos1-section {
  margin-top: 60px;
  margin-bottom: 20px;
}

.quienessomosi1-section {
  position: absolute;
  top: 25px;
  left: 270px;
}

.quienessomosi1-section {
  @media (max-width: 431px) {
    .responsive-invisibility();
  }
}

.quienessomosi2-section {
  padding-right: 20px;
}

////// --------------------------------------------------

.ayuda-section {
  margin: 10px 0 50px 0;
}

.ayudai1-section {
  position: absolute;
  top: 25px;
  left: 140px;
}

.ayudai1-section {
  @media (max-width: 260px) {
    .responsive-invisibility();
  }
}

.ayuda1-section {
  margin-top: 45px;
}

.ayuda2-section {
  border-bottom: 1px solid @fondoh;
}

.ayuda3-section {
  list-style: none;
  padding-left: 0;
  font-family: @font-exo;
  font-size: @font-size-h4;

  > li {
    border-bottom: 1px solid @fondoh;
    padding-top: (@line-height-computed / 2);
    padding-bottom: (@line-height-computed / 2);
  }

}

////// --------------------------------------------------

.formularios-section {
  margin: 10px 0 50px 0;
}

////// --------------------------------------------------

.footer-section {
  margin-top: 10px;
  padding-bottom: 5px;
  background-color: @fondoh;
  border-top: 1px solid @naranja2;
}

.footer1-section {
  margin: 50px 0 20px 0;
}

.footer1a1-section {
  position:relative;
  width:272px;
  height:51px;
  margin:0 auto;
  @media (max-width: 330px) {
    .responsive-invisibility();
  }
}

.footer1a2-section {
  z-index: 200;
  position:absolute;
  left:0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.footer1a2-section:hover {
  opacity:0;
}

.footer1a3-section {
  z-index: 100;
  position:absolute;
  left:0;
}

.footer1a4-section {
  position:relative;
  width:191px;
  height:35px;
  margin:0 auto;
  @media (min-width: 331px) {
    .responsive-invisibility();
  }
}

.footer1b-section {
  display: inline-block;
  margin-top: 10px;

  a {
    color: @gray-base;

  &:hover,
  &:focus {
    text-decoration: none;
    color: @azul3;
  }
  }
}

@media (min-width: @screen-sm-min) {
.footer1b-section {
  border-left: 1px dotted @gray-base;
}
}

@media (min-width: @screen-sm-min) {
.footer1b-section p {
  font-size: @font-size-large;
}
}

@media (max-width: @screen-xs-max) {
.footer1b-section p {
  font-size: @font-size-base;
  text-align: center;
  margin: 20px 0 0 0;
}
}

.footer1c-section {
  display: inline-block;
  margin-top: 10px;
}

@media (min-width: @screen-md-min) {
.footer1c-section {
  text-align: right;
}
}

@media (max-width: @screen-sm-max) {
.footer1c-section {
  text-align: center;
  margin: 30px 0 20px 0;
}
}

.footer2-section {
  p {
    font-size: @font-size-small;
    text-align: center;
  }

  a {
    color: @gray-base;

  &:hover,
  &:focus {
    text-decoration: none;
    color: @azul3;
  }
  }
}


////// -------------------------------------------------- ////// ////// //////
////// LESS FILES MODIFICATIONS
////// -------------------------------------------------- ////// ////// //////



////// --------------------------------------------------
////// mixins/buttons.less
////// --------------------------------------------------

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.button-variant(@color; @background; @border) {
  color: @color;
  background-color: @background;
  border-color: @border;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @blancob;
    background-color: @azul3;
        border-color: transparent;
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: @background;
          border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

// Button sizes
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;
}

////// --------------------------------------------------
////// mixins/labels.less
////// --------------------------------------------------

// Labels

.label-variant(@color) {
  background-color: @color;

  &[href] {
    &:hover,
    &:focus {
    color: @blancob;
    background-color: @azul3;
        border-color: transparent;
    }
  }
}

////// --------------------------------------------------
////// pager.less
////// --------------------------------------------------

//
// Pager pagination
// --------------------------------------------------


.pager {
  padding-left: 0;
  margin: @line-height-computed 0;
  list-style: none;
  text-align: center;
  &:extend(.clearfix all);
  li {
    display: inline;
    > a,
    > span {
      display: inline-block;
      padding: 5px 14px;
      background-color: @pager-bg;
      border: 1px solid @pager-border;
      border-radius: @pager-border-radius;
    }

    > a:hover,
    > a:focus {
      text-decoration: none;
      color: @blancob;
      background-color: @pager-hover-bg;
    }
  }

  .next {
    > a,
    > span {
      float: right;
    }
  }

  .previous {
    > a,
    > span {
      float: left;
    }
  }

  .disabled {
    > a,
    > a:hover,
    > a:focus,
    > span {
      color: @pager-disabled-color;
      background-color: @pager-bg;
      cursor: @cursor-disabled;
    }
  }
}

////// --------------------------------------------------
////// tables.less
////// --------------------------------------------------


thead th {
  background-color: darken(@table-bg, 3%);
}



//
// Tables
// --------------------------------------------------


table {
  background-color: @table-bg;
}
caption {
  padding-top: @table-cell-padding;
  padding-bottom: @table-cell-padding;
  color: @text-muted;
  text-align: left;
}
th {
  text-align: left;
}


// Baseline styles

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: @line-height-computed;
  border: 2px solid @table-border-color;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: @table-cell-padding;
        line-height: @line-height-base;
        vertical-align: top;
        border-top: 0px solid @table-border-color;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 0px solid @table-border-color;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 0px solid @table-border-color;
  }

  // Nesting
  .table {
    background-color: @body-bg;
  }
}


// Condensed table w/ half padding

.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: @table-condensed-cell-padding;
      }
    }
  }
}


// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: 2px solid @table-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 0px solid @table-border-color;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 0px;
    }
  }
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: @table-bg-accent;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  > tbody > tr:hover {
    background-color: @table-bg-hover;
  }
}


// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
  position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
  float: none;
  display: table-column;
}
table {
  td,
  th {
    &[class*="col-"] {
      position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-cell;
    }
  }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
.table-row-variant(active; @table-bg-active);
.table-row-variant(success; @state-success-bg);
.table-row-variant(info; @state-info-bg);
.table-row-variant(warning; @state-warning-bg);
.table-row-variant(danger; @state-danger-bg);


// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
    margin-bottom: (@line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid @table-border-color;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
  }
}

////// --------------------------------------------------
////// list-group.less
////// --------------------------------------------------

// List Groups

//
// List groups
// --------------------------------------------------


// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
  // No need to set list-style: none; since .list-group-item is block level
  margin-bottom: 20px;
  padding-left: 0; // reset padding because ul and ol
}


// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  // Place the border on the list items and negative margin up for better styling
  margin-bottom: -1px;
  background-color: @list-group-bg;
  border: 1px solid @list-group-border;

  // Round the first and last items
  &:first-child {
    .border-top-radius(@list-group-border-radius);
  }
  &:last-child {
    margin-bottom: 0;
    .border-bottom-radius(@list-group-border-radius);
  }
}


// Linked list items
//
// Use anchor elements instead of `li`s or `div`s to create linked list items.
// Includes an extra `.active` modifier class for showing selected items.

a.list-group-item {
  color: @list-group-link-color;

  .list-group-item-heading {
    color: inherit;
  }

  // Hover state
  &:hover,
  &:focus {
    text-decoration: none;
    color: @list-group-link-hover-color;
    background-color: @list-group-hover-bg;
    border-color: @azul3;
  }
}

.list-group-item {
  // Disabled state
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: @list-group-disabled-bg;
    color: @list-group-disabled-color;
    cursor: @cursor-disabled;

    // Force color to inherit for custom content
    .list-group-item-heading {
      color: inherit;
    }
    .list-group-item-text {
      color: @list-group-disabled-text-color;
    }
  }

  // Active class on item itself, not parent
  &.active,
  &.active:hover,
  &.active:focus {
    z-index: 2; // Place active items above their siblings for proper border styling
    color: @list-group-active-color;
    background-color: @list-group-active-bg;
    border-color: @list-group-active-border;

    // Force color to inherit for custom content
    .list-group-item-heading,
    .list-group-item-heading > small,
    .list-group-item-heading > .small {
      color: inherit;
    }
    .list-group-item-text {
      color: @list-group-active-text-color;
    }
  }
}


.list-group-item {
  &.disabled:hover,
  &.disabled:focus {
    border-color: @naranja1;
    }
  }


// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

.list-group-item-variant(success; @state-success-bg; @state-success-text);
.list-group-item-variant(info; @state-info-bg; @state-info-text);
.list-group-item-variant(warning; @state-warning-bg; @state-warning-text);
.list-group-item-variant(danger; @state-danger-bg; @state-danger-text);


// Custom content options
//
// Extra classes for creating well-formatted content within `.list-group-item`s.

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}



////// --------------------------------------------------
////// list-group.less
////// --------------------------------------------------




//
// List groups
// --------------------------------------------------


// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
  // No need to set list-style: none; since .list-group-item is block level
  margin-bottom: 20px;
  padding-left: 0; // reset padding because ul and ol
}


// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  // Place the border on the list items and negative margin up for better styling
  margin-bottom: -1px;
  background-color: @azul1;
  border: transparent;

  // Round the first and last items
  &:first-child {
    .border-top-radius(@list-group-border-radius);
  }
  &:last-child {
    margin-bottom: 0;
    .border-bottom-radius(@list-group-border-radius);
  }
}


// Linked list items
//
// Use anchor elements instead of `li`s or `div`s to create linked list items.
// Includes an extra `.active` modifier class for showing selected items.

a.list-group-item {
  color: @list-group-link-color;
  background-color: @list-group-bg;
  border: 1px solid @list-group-border;

  .list-group-item-heading {
    color: @list-group-link-heading-color;
  }

  // Hover state
  &:hover,
  &:focus {
    text-decoration: none;
    color: @list-group-link-hover-color;
    background-color: @list-group-hover-bg;
  }
}

.list-group-item {
  // Disabled state
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: @list-group-disabled-bg;
    color: @list-group-disabled-color;
    cursor: @cursor-disabled;

    // Force color to inherit for custom content
    .list-group-item-heading {
      color: inherit;
    }
    .list-group-item-text {
      color: @list-group-disabled-text-color;
    }
  }

  // Active class on item itself, not parent
  &.active,
  &.active:hover,
  &.active:focus {
    z-index: 2; // Place active items above their siblings for proper border styling
    color: @list-group-active-color;
    background-color: @list-group-active-bg;
    border-color: @list-group-active-border;

    // Force color to inherit for custom content
    .list-group-item-heading,
    .list-group-item-heading > small,
    .list-group-item-heading > .small {
      color: inherit;
    }
    .list-group-item-text {
      color: @list-group-active-text-color;
    }
  }
}


// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

.list-group-item-variant(success; @state-success-bg; @state-success-text);
.list-group-item-variant(info; @state-info-bg; @state-info-text);
.list-group-item-variant(warning; @state-warning-bg; @state-warning-text);
.list-group-item-variant(danger; @state-danger-bg; @state-danger-text);


// Custom content options
//
// Extra classes for creating well-formatted content within `.list-group-item`s.

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}
